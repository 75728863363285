var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _c("div", { staticClass: "grid grid-cols-12 gap-6" }, [
      _c(
        "div",
        {
          staticClass:
            "col-span-8 col-start-3 bg-white rounded-lg overflow-hidden border border-gray-400 p-4 mt-20",
        },
        [
          _c("h1", [_vm._v("404")]),
          _c("p", { staticClass: "mb-6 text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("pages.commons.404.message")) + " "),
          ]),
          _c(
            "router-link",
            { staticClass: "w-full mx-auto", attrs: { to: "/" } },
            [
              _c(
                "button",
                {
                  staticClass:
                    "px-2 py-2 w-full text-center bg-base-yellow font-bold",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("pages.commons.404.goto_top")) + " "
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }